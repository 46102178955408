@import "global.scss";
.fullScreenWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5em;
  box-sizing: border-box;

  img {
    width: 20em;
    max-width: 500px;
    height: auto;
    margin-bottom: 2em;
  }

  p {
    color: $text-primary;
    font-size: 3em;
    font-weight: 300;
    text-align: center;
  }

  .joinCode {
    margin: .3em 0 .2em 0;
    color: $uob-red;
    font-family: monospace;
    font-weight: 700;
    font-size: 6em;
  }
}

