@import "global.scss";
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2em;
  margin-bottom: 2em;
  border-bottom: 1px solid $light-gray;
  color: $text-primary;

  .title {
    font-weight: 300;
    font-size: 1.5em;
  }

  .horizontalFill {
    flex-grow: 1;
  }

  .joinGame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    label {
      height: 2em;
      font-weight: 400;
      color: $text-primary;

      input {
        width: 100%;
        border: none;
        border-bottom: 1px solid $text-primary;
        color: $text-primary;
        font-weight: 400;
        margin: .05em 0 0 0;
        padding: .5em 0 4px 0;

        &:focus {
          border-bottom: 2px solid $uob-red;
          padding: .5em 0 3px 0;
          outline: none;
        }

        &:disabled {
          color: $text-primary-lighter;
          background-color: $light-gray;
          cursor: not-allowed;
        }
      }
    }

    input[type=submit] {
      font-size: 1em;
      height: 2em;
      font-weight: 500;
      padding: .5em .8em;
      margin-left: 1em;
      color: white;
      background-color: $uob-red;
      border: none;
      border-radius: .125em;
      box-sizing: border-box;

      &:focus {
        border: 1px solid white;
        outline: 2px solid $uob-red;
      }

      &:disabled {
        border: 1px solid transparent;
        color: #cccccc;
        background: #8d8d8d;
        outline: none;
      }
    }
  }

  button {
    font-size: 1em;
    width: 2em;
    height: 2em;
    padding: .5em;
    border: 1px solid #999999;
    border-radius: .125em;
    background-color: white;
    margin: 0 0 0 .5em;

    &:hover {
      background-color: $light-gray;
    }
  }
}

.gameList {
  width: 90%;
  margin: 5em auto 1em auto;
}



.noGames {
  p {
    color: $text-primary;
    text-align: center;
  }
}

.errorBanner {
  text-align: center;
  margin: 48vh 0 0 0;
}
