@import "global.scss";
.navbarItem {
  padding: .75em 1em;
  margin: .25em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $light-gray;



  .icon {
    font-size: 2em;
    width: 1em;
    height: 1em;
    margin: 0;
  }

  .label {
    font-weight: 700;
    font-size: .8em;
    color: $text-primary;
  }

  &.active {
    border-left: .35em $uob-red solid;

    .label {
      color: $uob-red;
    }
  }
}

.expanded {
  justify-content: flex-start;

  .icon {
    margin-right: .5em;
  }
}
