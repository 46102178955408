@import "global.scss";
.console {
  font-family: 'Monaco', 'monospace';
  font-size: .9em;
  max-height: 100%;
  overflow-y: scroll;
  border-top: 1px solid $light-gray;

  p {
    margin: .5em 0;
  }
}

.line {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: .5em 0;
  font-family: 'Monaco', 'monospace';
  opacity: .8;

  &:last-of-type {
    opacity: 1;
    padding-bottom: 2em;
  }
}

.info {
  color: $text-primary;
}

.success {
  color: $success-text;
  background-color: $success-background;
}

.error {
  color: $error-text;
  background-color: $error-background;
}
