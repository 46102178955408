@import "global.scss";
.documentationList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .item {
    font-size: .9em;
    font-weight: 300;
    color: $text-primary;
    margin: .5em .5em .5em 2em;

    .active {
      color: $uob-red;
    }
  }
}
