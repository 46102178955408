@import "global.scss";
.sourceCodeListEntry {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $light-gray;
  border-radius: .25em;
  padding: 0 1em;
  margin: 1em 0;
  color: $text-primary;

  .sourceCodeHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-gray;
    padding: 1em;
    width: 100%;

    .name {
      font-weight: 700;
      margin: 0 1em 0 0;
    }

    .link {
      font-size: 1em;
      height: 2em;
      font-weight: 500;
      padding: .4em .8em;
      color: $uob-red;
      background-color: white;
      border: 1px solid $uob-red;
      border-radius: .125em 0 0 .125em;
      box-sizing: border-box;
      margin: 0 0 0 1em;
    }

    button {
      font-size: 1em;
      width: 2em;
      height: 2em;
      padding: .5em;
      border: 1px solid #999999;
      border-radius: .125em;
      background-color: white;
      margin: 0 0 0 .5em;

      &:hover {
        background-color: $light-gray;
      }
    }
  }

  .sourceCodeSubmissions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .submission {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $light-gray;
      padding: 1em;
      width: 100%;

      .mapName {
        font-weight: 500;
        margin: 0 1em;
      }

      .score {
        font-weight: 500;
        margin: 0 1em;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.errorBanner {
  text-align: center;
  margin: 48vh 0 0 0;
}

.noSubmissions {
  width: 100%;
  text-align: center;
  color: $text-primary;
  padding: 1em;
}

.horizontalFill {
  flex-grow: 1;
}
