@import "global.scss";
.maps {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .mapsWrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.errorBanner {
  text-align: center;
  margin: 48vh 0 0 0;
}
