@import "global.scss";
.navbarLogo {
  margin: 1em 1em 0 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    height: 3em;
  }

  button {
    font-size: 1.25em;
    margin: 1em 0 0 0;
    display: block;
    border: none;
    background-color: unset;
    box-sizing: content-box;
  }
}

.expanded {
  flex-direction: row;
  justify-content: space-between;
  margin: 1em;

  button {
    margin: 0 .5em 0 1em;
    transform: rotate(180deg);
  }
}
