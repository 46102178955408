@import "global.scss";
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 250px;
    max-height: 250px;
  }

  p {
    margin: 1em;
  }
}
