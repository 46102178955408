@import "global.scss";
.profileLinks {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  font-size: .9em;
  color: $uob-red;
  margin: .5em 0;

  .icon {
    margin-right: .5em;
  }
}
