@import "global.scss";
.signLayout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formBox {
  width: 20em;
  height: auto;
  padding: 1em;
  background-color: #ffffff;
  border-radius: .25em;
  border: 1px solid $light-gray;

  h1 {
    font-size: 1.2em;
    font-weight: 700;
    color: $text-primary;
    margin-bottom: .8em;
  }

  p {
    font-size: .9em;
    color: $text-primary;
  }

  form {
    width: 100%;
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    label {
      width: 100%;
      font-size: .8em;
      font-weight: 400;
      color: $text-primary;
      margin: .5em 0 1em 0;

      input {
        width: 100%;
        border: none;
        border-bottom: 1px solid $text-primary;
        color: $text-primary;
        font-weight: 400;
        margin: .2em 0 0 0;
        padding: .5em 0 4px 0;

        &:focus {
          border-bottom: 2px solid $uob-red;
          padding: .5em 0 3px 0;
          outline: none;
        }

        &:disabled {
          color: $text-primary-lighter;
          background-color: $light-gray;
          cursor: not-allowed;
        }
      }

      &.checkboxLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        input[type=checkbox] {
          width: auto;
          height: auto;
          margin: 0 1em 0 0;
          border: 1px solid transparent;

          &:focus {
            outline: 2px solid $uob-red;
          }
        }
      }
    }

    input[type=submit] {
      width: 100%;
      font-weight: 400;
      margin: 1em 0;
      background: $uob-red;
      color: #ffffff;
      padding: .5em;
      font-size: 1em;
      border-radius: .25em;
      border: 1px solid transparent;

      &:focus {
        border: 1px solid white;
        outline: 2px solid $uob-red;
      }

      &:disabled {
        border: 1px solid transparent;
        color: #cccccc;
        background: #8d8d8d;
        outline: none;
      }
    }
  }
}

.formText {
  font-size: 1em;

  a {
    color: $uob-red;
    font-weight: 400;
  }
}

.small {
  margin: .7em 0;
  display: block;
  font-size: .8em;
}

.inlineButton {
  background: none;
  color: $uob-red;
  font-weight: 500;
}

.message {
  margin: 1em 0;
  text-align: center;

  &>span {
    width: 20em;
    padding: 1em;
    display: block;
    border-radius: .25em;
  }

  .normal {
    background-color: $normal-background;
    color: $normal-text;
    border-color: $normal-text;
  }

  .success {
    background-color: $success-background;
    color: $success-text;
    border-color: $success-text;
  }

  .error {
    background-color: $error-background;
    color: $error-text;
    border-color: $error-text;
  }

}
