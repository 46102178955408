@import "global.scss";
.documentation {
  width: 90%;
  max-width: 900px;
  margin: 5em auto 1em auto;
  padding: 0 0 3em 0;

  .image {
    width: 100%;
    display: block;
    margin: 3em auto 3em auto;
    box-sizing: border-box;
    border: 1px solid $light-gray;
  }

  h1 {
    font-weight: 300;
    font-size: 2em;
    color: $text-primary;
    margin: 1em 0;
  }

  a {
    color: $uob-red;
    text-decoration: underline;
    font-weight: 700;
  }

  .section {
    width: 100%;
    margin: 1em 0 2em 0;

    h2 {
      color: $text-primary;
      font-weight: 500;
      font-size: 1.4em;
      margin: 0 0 1em 0;
    }

    h3 {
      color: $text-primary;
      font-size: 1.2em;
      margin: 0 0 1em 0;
    }

    code {
      border: 1px solid $light-gray;
      padding: 1em;
      margin: 1em;
      display: block;
    }


    p {
      color: $text-primary;
      font-weight: 500;
      font-size: 1em;
      line-height: 1.5em;
      margin: 0 .5em;

      &.large {
        font-size: 1.2em;
        color: $uob-red;
      }
    }

    .code {
      font-size: .9em;
      font-family: 'Monaco', monospace, sans-serif;
      font-weight: 400;
      color: $uob-red;
    }

    .apiDoc {
      border: 1px solid $light-gray;
      padding: 2em;
      margin: 1em 0;
    }

    .apiElement {
      font-size: 1.1em;
      font-family: 'Monaco', monospace, sans-serif;
      font-weight: 700;
      color: $uob-red;
      text-decoration: underline;
    }

    .apiSection {
      display: block;
      font-size: .9em;
      font-weight: 700;
      color: $text-primary;
      margin: 1em 0 .5em 0;
    }

    .apiProperty {
      font-size: .9em;
      font-family: 'Monaco', monospace, sans-serif;
      font-weight: 400;
      color: $uob-red;
    }

    .nothing {
      font-size: 1em;
      font-style: italic;
      margin-left: 0;
      margin-right: 0;
    }

    .bold {
      font-weight: bold;
    }
  }


}
