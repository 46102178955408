@import "global.scss";
.profilePicture {
  border-radius: 50%;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: $text-primary;
}

.name {
  font-weight: 500;
  font-size: .7em;
}

.emailAddress {
  font-size: .6em;
}
