@import "global.scss";
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2em;
  margin-bottom: 2em;
  border-bottom: 1px solid $light-gray;
  color: $text-primary;

  .title {
    font-weight: 300;
    font-size: 1.5em;
  }

  .horizontalFill {
    flex-grow: 1;
  }

  .newSourceCode {
    font-size: 1em;
    height: 2em;
    font-weight: 500;
    padding: .5em .8em;
    color: white;
    background-color: $uob-red;
    border-radius: .125em;
    box-sizing: border-box;
  }

  button {
    font-size: 1em;
    width: 2em;
    height: 2em;
    padding: .5em;
    border: 1px solid #999999;
    border-radius: .125em;
    background-color: white;
    margin: 0 0 0 .5em;

    &:hover {
      background-color: $light-gray;
    }
  }
}

.sourceCodeList {
  width: 90%;
  margin: 5em auto 1em auto;
}


.noSourceCode {
  p {
    color: $text-primary;
    text-align: center;
  }
}

.errorBanner {
  text-align: center;
  margin: 48vh 0 0 0;
}
