$primary: #5d8dbd;
$uob-red: #b01c2e;
$uob-red-alt: #af1e2d;
$uob-white: #ffffff;
$uob-black: #000000;
$uob-cool-grey: #e5e6e5;

$light-gray: #f1f1f1;

$text-primary: #666666;
$text-primary-lighter: #888888;

$shadow: #aaaaaa;

$normal-text: $text-primary;
$normal-background: #efefef;

$error-text: #760505;
$error-background: #eecaca;

$success-text: #247d24;
$success-background: #c6f1c6;
