@import "global.scss";
.mapName {
  margin: 1em;
  width: 14em;
  max-width: 20em;
  height: auto;
  padding: .75em;
  border: 1px solid $light-gray;
  border-radius: 1em;

  img {
    width: 100%;
    height: auto;
  }

  h2 {
    color: $text-primary;
    font-size: 1.1em;
    font-weight: 700;
    margin: .5em 0;
  }

  p {
    color: $text-primary;
    font-size: .8em;

    .difficulty {
      font-weight: 700;
    }
  }
}
