@import "global.scss";
.sourceCode {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .topBar {
    width: 100%;
    height: 3em;
    border-bottom: 1px solid $light-gray;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    form {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: .5em 1em;
      max-width: 40vw;

      input {
        border: none;
        color: $text-primary;
        font-size: 1.2em;
        font-weight: 300;
        font-family: monospace;
        outline: none;
        background-color: transparent;

        &:disabled {
          background-color: transparent;
        }
      }

      button {
        padding-top: .1em;
        font-size: 1.2em;
      }
    }

    .submitToGame {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 1em;

      button {
        font-size: 1em;
        height: 2em;
        font-weight: 500;
        padding: .4em .8em;
        color: $uob-red;
        background-color: white;
        border: 1px solid $uob-red;
        border-radius: .125em 0 0 .125em;
        box-sizing: border-box;
        margin: 0 0 0 1em;

        &:disabled {
          cursor: default;
          color: $text-primary-lighter;
          background-color: $light-gray;
        }
      }
    }
  }

  .ide {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80%;
    flex-grow: 1;

    .left {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;

      .viewportControlsWrapper {
        width: 100%;
        height: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 1em;
      }

      .viewportControls {
        height: 2em;
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 100;

        .horizontalFill {
          flex-grow: 1;
        }

        .spacer {
          width: 1em;
          display: inline-block;
        }

        .playbackControls {

          button {
            width: 2em;
            height: 2em;
            background-color: #ffffff;
            border-bottom: 1px solid $light-gray;
            border-right: 1px solid $light-gray;
            box-sizing: border-box;

            &:disabled {
              &>* {
                opacity: .35;
              }
            }

            &:first-child {
              border-left: 1px solid $light-gray;
              border-bottom-left-radius: .25em;
            }

            &:last-child {
              border-bottom-right-radius: .25em;
            }
          }
        }
      }

      .viewport {
        background-color: #78b7e3;
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 50%;
      height: 100%;
      border-left: 1px solid $light-gray;
      box-sizing: border-box;


      .editorControlsWrapper {
        height: 0;
        position: relative;
        top: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 10;

        .horizontalFill {
          height: 2em;
          flex-grow: 1;
          border-bottom: 1px solid $light-gray;
        }

        .editorControls {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid $light-gray;

          button {
            height: 2em;
            font-size: 1em;
            background-color: #ffffff;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 .5em;
            color: $text-primary;
            //border-bottom: 1px solid $light-gray;
            border-right: 1px solid $light-gray;

            .icon {
              font-size: 1.5em;
            }

            span {
              margin-left: .45em;
              font-size: .9em;
            }

            &.buildButton {
              color: $uob-red;
            }

            &:disabled {
              &>* {
                opacity: .35;
              }

              &>span {
                opacity: .5
              }

              &.buildButton {
                color: $text-primary;
              }
            }

            &:first-child {
              border-left: 1px solid $light-gray;
            }
          }
        }
      }

      .editor {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 2em;
        font-family: 'Helvetica', 'Arial', sans-serif;
        font-weight: 300;
      }
    }
  }

  .editorOutput {
    width: 100%;
    height: 20%;
    background-color: #ffffff;
    padding: 0 0.5em;
    box-sizing: border-box;
  }
}
