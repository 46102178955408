@import "global.scss";
.leaderboardEntry {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $light-gray;
  border-radius: .25em;
  padding: 0 1em;
  margin: 1em 0;
  color: $text-primary;

  .leaderboardEntryHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-gray;
    padding: 1em;
    width: 100%;

    .mapName {
      font-weight: 700;
      margin: 0 1em 0 0;
    }
  }

  .leaderboardEntrySubmissions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .submission {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $light-gray;
      padding: 1em;
      width: 100%;

      .user {
        font-weight: 500;
        margin: 0 1em;

        .sourceCode {
          padding-left: .5em;
          color: $text-primary-lighter;
        }
      }

      .score {
        font-weight: 500;
        margin: 0 1em;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.noSubmissions {
  width: 100%;
  text-align: center;
  color: $text-primary;
  padding: 1em;
}
